import "@fortawesome/fontawesome-free/css/all.min.css";
import "@fontsource/inter"
import "./styles.css"

import React from 'react';
import {ToastProvider} from "react-toast-notifications"
import Layout from "./src/components/layout";
import {AuthProvider} from "./src/context/AuthContext";

export const wrapRootElement = ({element}) => (
    <ToastProvider
        autoDismissTimeout={3000}
        autoDismiss={true}
        placement={'top-center'}>
        <AuthProvider>
            <Layout>
                {element}
            </Layout>
        </AuthProvider>
    </ToastProvider>
)