import React, {createContext, useState, useContext, useEffect} from 'react';

// Create a context for auth
const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null);

    useEffect(() => {
        const storedToken = localStorage.getItem('authToken');
        const tokenExpiration = localStorage.getItem('authTokenExpiration');

        if (storedToken && tokenExpiration) {
            const expirationTimestamp = parseInt(tokenExpiration, 10); // Convert to integer
            const now = Date.now(); // Current timestamp in milliseconds

            // Check if the token is still valid
            if (now < expirationTimestamp) {
                setToken(storedToken);
            } else {
                // Token has expired
                localStorage.removeItem('authToken');
                localStorage.removeItem('authTokenExpiration');
            }
        }
    }, []);

    // Function to handle login
    const login = (newToken, expiresAt) => {
        const expirationTimestamp = parseInt(expiresAt, 10); // Convert to integer
        setToken(newToken);
        localStorage.setItem('authTokenExpiration', expirationTimestamp.toString()); // Store timestamp as string
        localStorage.setItem('authToken', newToken); // Store token in localStorage
    };

    // Function to handle logout
    const logout = () => {
        setToken(null);
        localStorage.removeItem('authTokenExpiration');
        localStorage.removeItem('authToken');
    };

    return (
        <AuthContext.Provider value={{ token, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
