import axios from "axios";

// Create an Axios instance
const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});
// Add a request interceptor
api.interceptors.request.use(
    (config) => {
        // Get token and expiration time from localStorage
        const token = localStorage.getItem("authToken");
        const expirationTime = localStorage.getItem("authTokenExpiration");

        // Check if the token is expired
        if (token && expirationTime && new Date().getTime() < expirationTime) {
            // If token is valid, set it in the request headers
            config.headers.Authorization = `Bearer ${token}`;
        } else {
            // If token is expired, remove it from localStorage
            localStorage.removeItem("authToken");
            localStorage.removeItem("authTokenExpiration");
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default api;