import React, {useEffect, useState} from 'react';
import {Link} from 'gatsby';
import styled from 'styled-components';
import {useAuth} from '../context/AuthContext';
import api from "./api"; // Import useAuth to access the global auth state

const Navbar: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isEmailVerified, setIsEmailVerified] = useState(true); // Assume verified by default
    const [verificationSent, setVerificationSent] = useState(false);
    const {token, login} = useAuth(); // Get the token from the global auth state

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (token) {
            api.get('/api/v1/user/me')
                .then((response) => {
                    const {emailVerified} = response.data;
                    setIsEmailVerified(emailVerified);
                })
                .catch((error) => {
                    console.error('Error fetching user details:', error);
                });
        }
    }, [token]);

    const resendVerificationEmail = () => {
        api.post('/api/v1/auth/resend-verification')
            .then(() => {
                setVerificationSent(true);
            })
            .catch((error) => {
                console.error('Error resending verification email:', error);
            });
    };

    const handleMenuItemClick = () => {
        setIsOpen(false); // Close the menu when a link is clicked
        window.scrollTo(0, 0)
    };

    return (
        <NavContainer>
            <Nav>
                <Container>
                    <Logo to="/">DuckDuckLink</Logo>
                    <Hamburger onClick={toggleMenu}>
                        <Bar isOpen={isOpen}/>
                        <Bar isOpen={isOpen}/>
                        <Bar isOpen={isOpen}/>
                    </Hamburger>
                    <Menu isOpen={isOpen}>
                        <MenuItem to="/" activeClassName="active" onClick={handleMenuItemClick}>
                            Features
                        </MenuItem>
                        <MenuItem to="/pricing" activeClassName="active" partiallyActive={true}
                                  onClick={handleMenuItemClick}>
                            Pricing
                        </MenuItem>
                        <MenuItem to="/contact" activeClassName="active" partiallyActive={true}
                                  onClick={handleMenuItemClick}>
                            Contact Us
                        </MenuItem>
                        <MenuItem to={token ? '/logout' : '/login'} partiallyActive={true} activeClassName="active"
                                  onClick={handleMenuItemClick}>
                            {token ? 'Logout' : 'Login'}
                        </MenuItem>
                        <Link to="/login" onClick={handleMenuItemClick}>
                            <GetStartedButton>Get Started</GetStartedButton>
                        </Link>
                    </Menu>
                </Container>
            </Nav>
            {!isEmailVerified && (
                <VerificationBar>
                    <VerificationText>Please check your inbox to verify your email address.</VerificationText>
                    {verificationSent ? (
                        <VerificationText>Email sent! Please check your inbox.</VerificationText>
                    ) : (
                        <ResendButton onClick={resendVerificationEmail}>
                            Resend
                        </ResendButton>
                    )}
                </VerificationBar>
            )}
        </NavContainer>
    );
};

// Styled-components remain unchanged
const NavContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 1000;
`

const Nav = styled.nav`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F3EEE3;
    color: #000;
    padding-top: 15px;
    padding-bottom: 5px;
    border-bottom: 4px solid;
    border-image: linear-gradient(to bottom, #F3EEE3, #EEDFDE);
    border-image-slice: 10;
`;

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
`;

const Logo = styled(Link)`
    font-size: 2.5rem;
    font-weight: 700;
    color: #000;
    text-decoration: none;

    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
`;

const Hamburger = styled.div`
    display: none;
    flex-direction: column;
    cursor: pointer;
    width: 30px;
    height: 21px;
    justify-content: space-between;

    @media (max-width: 768px) {
        display: flex;
    }
`;

const Bar = styled.div<{ isOpen: boolean }>`
    width: 100%;
    height: 3px;
    background-color: #000;
    transition: transform 0.6s ease, opacity 0.6s ease;

    &:nth-child(1) {
        transform: ${({isOpen}) => (isOpen ? 'translateY(9px) rotate(45deg)' : 'translateY(0) rotate(0)')};
    }

    &:nth-child(2) {
        opacity: ${({isOpen}) => (isOpen ? '0' : '1')};
    }

    &:nth-child(3) {
        transform: ${({isOpen}) => (isOpen ? 'translateY(-9px) rotate(-45deg)' : 'translateY(0) rotate(0)')};
    }
`;

const Menu = styled.div<{ isOpen: boolean }>`
    display: flex;
    align-items: center;
    z-index: 1000;

    @media (max-width: 768px) {
        flex-direction: column;
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        background: #F3EEE3;
        transition: max-height 0.6s ease;
        max-height: ${({isOpen}) => (isOpen ? '500px' : '0')};
        overflow: hidden;
        visibility: ${({isOpen}) => (isOpen ? 'visible' : 'hidden')};
        border-bottom: 4px solid;
        border-image: linear-gradient(to bottom, #F3EEE3, #EEDFDE);
        border-image-slice: 10;
    }
`;

const MenuItem = styled(Link)`
    margin: 0 15px;
    text-decoration: none;
    color: #000000;
    padding-bottom: 6px;
    position: relative;
    font-weight: 500;
    font-size: 1.125rem;

    &.active {
        font-weight: 700;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 6px;
            background-color: #eff87a;
            border-radius: 100px;
        }
    }

    &:hover {
        color: #555;
        font-weight: 700;
    }

    @media (max-width: 768px) {
        margin: 10px 0;
    }
`;

const GetStartedButton = styled.button`
    background-color: #000000;
    height: 54px;
    color: #fff;
    border: none;
    padding: 18px 32px;
    border-radius: 100px;
    cursor: pointer;
    margin-left: 20px;
    font-weight: 600;
    font-size: 18px;

    &:hover {
        background-color: #444444;
        font-weight: 700;
    }

    @media (max-width: 768px) {
        margin: 20px 0;
    }
`;

const VerificationBar = styled.div`
    background-color: #ffcc00;
    color: #000;
    text-align: center;
    padding: 5px 5px;
    left: 0;
    z-index: 999;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
`;

const VerificationText = styled.p`
    font-size: 16px;
    font-weight: 500;
    margin: 0;
`;

const ResendButton = styled.button`
    background-color: #000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 100px;
    cursor: pointer;
    font-weight: 600;
    font-size: 12px;

    &:hover {
        background-color: #444;
        font-weight: 700;
    }
`;

export default Navbar;
